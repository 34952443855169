html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-size: 100%;
}
body {
  line-height: inherit;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  font-family: cash-market-rounded, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 24px;
}

html,
body {
  width: 100%;
  height: 100%;
}

h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: underline;
}
svg {
  display: inline-block;
  vertical-align: middle;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}
